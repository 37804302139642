<template>
      <div class="index container">

        <transition name="fade">
          <router-view @showParent="showParent">
          </router-view>
        </transition>
        
        <div v-if="showParentPage">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item
              :exact="true"
              :to="{ name: 'accountClosing', params: {} }"
              active
            >
              Account Closing
            </v-breadcrumbs-item>
          </v-breadcrumbs>

        <div class="d-flex flex-column flex-lg-row">
          <v-text-field
            dense
            v-model="searchTerms"
            @keyup.enter="search"
            @click:clear="clearSearch"
            @click:append="search"
            placeholder="Press enter to begin search"
            append-icon="mdi-magnify"
            outlined
            clearable
            label="Search"
          />
          <v-btn
            class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
            color="primary"
            :to="{ name: 'accountClosingCreate', params: {} }"
          >
            Create Account Closing
          </v-btn>
        </div>

        <div class="table-container">
          <v-data-table
            :headers="headers"
            :items="accountClosingList"
            :items-per-page="pagination.pageSize"
            :loading="!accountClosingProcessed"
            :server-items-length="pagination.totalSize"
            :page="pagination.current"
            :disable-pagination="!accountClosingList || accountClosingList.length < 1"
            :hide-default-header="!accountClosingList || accountClosingList.length < 1"
            :hide-default-footer="!accountClosingList || accountClosingList.length < 1"
            :options.sync="options"
            item-key="id"
            class="elevation-1"
            @update:options="pageChanged"
          >
            
            <template v-slot:item.closingDate="{ item }">
              <a @click="view(item)">{{ item.closingDate }}</a>
            </template>
            
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="edit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="remove(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
      <confirmation-dialog ref="confirm" />
    </div>
  </template>

    <script>
    import { mapActions, mapGetters } from "vuex";
  
    export default {
      data(){
        return {
          pagination: {
            current: 1,
            pageSize: 10,
            totalSize: 100
          },
          options: { //define default sorting option here.
            sortBy: [],
            sortDesc: []
          },
          accountClosingList: [],
          showParentPage: true,
          
      headers: [
        {
          text: "ID",
          value: "id",
          align: "start",
          sortable: false
        },
        {
          text: "Closed Date",
          value: "closingDate",
          align: "start",
          sortable: false
        },
        {
          text: "By User",
          value: "adminUserId",
          align: "start",
          sortable: false
        },
        {
          text: "Created On",
          value: "recordedTime",
          align: "start",
          sortable: false
        },
        
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
          sort: {
            key: null
          },
          searchTerms : ''
        }
      },
      computed: {
        ...mapGetters("accountClosing", ["accountClosingProcessed", "getAccountClosings"]),
      },
      methods: {
        ...mapActions("messages", [
          "addErrorMessage",
          "addMessage",
          "addSuccessMessage"
        ]),
        ...mapActions("accountClosing", ["deleteAccountClosing", "fetchAccountClosings"]),
        view(item){
          this.$router.push({ name: 'accountClosingView', params: { id: item.id }})
        },
        edit(item){
          this.$router.push({ name: "accountClosingEdit", params: { id: item.id }})
        },
        async remove(item){
          let confirm = await this.$refs.confirm.open(
            "Confirm Delete",
            "Do you want to delete this accountClosing?"
          );
          if(confirm){
            this.deleteAccountClosing(item.id)
              .then( () => {
                this.addSuccessMessage("accountClosing was deleted successfully");
                this.getResources();
              }).catch( () => {
                this.addErrorMessage("Server response with error ");
              });
          }
        },
        getResources() {
          //default to sorting by ID desc if not defined
          const sortBy =
            this.options["sortBy"] && this.options["sortBy"].length > 0
              ? this.options.sortBy[0]
              : "id";
          const sortDesc =
            this.options["sortDesc"] && this.options["sortDesc"].length > 0
              ? this.options.sortDesc[0]
              : true;
          const params = {
            page: this.pagination.pageIndex && this.pagination.pageIndex > 0 ? this.pagination.pageIndex-1:0,
            size: this.pagination.pageSize,
            sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
          };
          this.fetchAccountClosings(params)
            .then(response => {
              if(response){
                this.pagination.totalSize = response.totalElements;
                this.accountClosingList = response.content;
              }
            })
            .catch(error => {
              this.addErrorMessage("Server response with error " + error);
            });
        },
        showParent(show) {
          this.showParentPage = show;
        },
        pageChanged(pagination) {
          this.pagination.pageIndex = pagination.page;
          this.pagination.pageSize = pagination.itemsPerPage;

          this.getResources()
        },
        search(){
          this.getResources();
        },
        clearSearch(){
          this.searchTerms = null;
          this.getResources();
        }
      },
      watch: {
        'pagination.numberRegisterForPage': function(){
            this.pagination.current = -1;
        },
      },
      beforeRouteUpdate(to, from, next) {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        this.showParentPage = toDepth < fromDepth;
        if (this.showParentPage) {
          this.getResources();
        }
        next();
      }
    }
    </script>

    